import _ from 'lodash';

const FORM_URL = "https://script.google.com/macros/s/AKfycbxFe7LxvzNwLjhfZJgBclFCKQAikBpJb5sZY-6jcbpOVt1ELb4/exec"

const toggleHasSubmitted = () => {
  const signUpContainer = document.getElementById("sign-up-container") || document.getElementsByClassName('main-description')[0]
  if (!signUpContainer) {
    return
  }

  const header = signUpContainer.getElementsByTagName("h5")[0]
  if (header) {
    header.innerText = "Thanks for signing up!"
  }

  const image = document.getElementsByClassName("sign-up-img")[0]
  if (image) {
    image.src = "/static/submitted_success.png"
  }

  const form = signUpContainer.getElementsByTagName("form")[0]
  signUpContainer.removeChild(form)

  const successMessage = document.getElementById("success-message")
  if (successMessage) {
    successMessage.style.display = "flex"
  }
}

window.toggleHasSubmitted = toggleHasSubmitted;

const signUpForm = document.getElementsByClassName("sign-up-form")
if (signUpForm.length > 0) {
	signUpForm[0].addEventListener("submit", function(e) {
    toggleHasSubmitted()
		e.preventDefault()
    const { email } = e.target.elements;
		const url = FORM_URL + `?email=${encodeURIComponent(email.value)}`
		fetch(url, {
			method: 'GET',
			mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
			},
		})
  })
}

let featuresAreOpen = false
const featuresButton = document.getElementById("features")
console.log(featuresButton)
if (featuresButton) {
  const featuresList = document.getElementById("features-list")
  featuresList.addEventListener('mouseleave', _.debounce(() => {
    featuresList.setAttribute('class', 'close')
    featuresAreOpen = false
  }, 100))
  featuresButton.addEventListener("click", e => {
    console.log(e)
    featuresList.setAttribute('class', featuresAreOpen ? 'close' : 'open')
    featuresAreOpen = !featuresAreOpen
  })
}
